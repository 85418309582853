import Link, { LinkProps } from 'next/link'
/** @jsxImportSource theme-ui */
import { ComponentPropsWithoutRef, PropsWithChildren, forwardRef } from 'react'
import { useTheme } from 'theme'
import { TensorUIBaseProps } from 'typ'

export type NextLinkProps = LinkProps &
  Omit<ComponentPropsWithoutRef<'a'>, 'style' | 'href'> & {
    variant?: 'themed' | 'unstyled'
  } & TensorUIBaseProps

export const NextLink = forwardRef<HTMLAnchorElement, PropsWithChildren<NextLinkProps>>(
  ({ children, style, variant = 'unstyled', ...props }, ref) => {
    const theme = useTheme()
    const baseStyles = variant === 'themed' ? theme.styles.a : {}

    return (
      <Link
        {...props}
        ref={ref}
        sx={{
          textDecoration: 'none',
          ...baseStyles,
          ...style,
        }}
      >
        {children}
      </Link>
    )
  }
)

NextLink.displayName = 'NextLink'
