/** @jsxImportSource theme-ui */
import { PropsWithChildren, ReactNode, forwardRef } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import { noSelect, useTheme } from 'theme'
import { Button as ThemeUIButton, ButtonProps as ThemeUIButtonProps } from 'theme-ui'
import { TensorUIBaseProps } from 'typ'
import { Box, Flex, Grid } from '../../layout'

export type ButtonSize = 'sm' | 'md' | 'lg'

type SizedButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'danger' | 'danger-filled' | 'gray'

type BaseProps = Omit<ThemeUIButtonProps, 'sx' | 'style'> &
  TensorUIBaseProps & {
    Icon?: ReactNode
    size?: ButtonSize
  }
export type SizedButtonProps = BaseProps & {
  variant?: SizedButtonVariant
  isLoading?: boolean
}

export type TextButtonProps = BaseProps & {
  variant: 'text'
  isLoading?: never
}
export type ButtonProps = SizedButtonProps | TextButtonProps

const getVariant = (variant: SizedButtonVariant | 'text', size: ButtonSize | undefined) => {
  if (variant === 'text' && !size) return 'text'
  return `${variant}-${size ?? 'md'}`
}

export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (
    { children, Icon, isLoading, type = 'button', variant = 'primary', size, style, ...props },
    ref
  ) => {
    const theme = useTheme()
    const { rawColors } = theme
    const sizedVariant = getVariant(variant, size)
    const buttonColor = theme.buttons[sizedVariant].color as keyof typeof rawColors

    const showLoading = isLoading
    const loaderProps = {
      margin: 2,
      color: props.disabled ? rawColors.gray3 : rawColors[buttonColor],
      loading: true,
      css: '',
      size: size === 'sm' ? 8 : size === 'md' ? 10 : 12,
      speedMultiplier: 1,
    }

    return (
      <ThemeUIButton {...props} variant={sizedVariant} sx={style} type={type} ref={ref}>
        {Icon ? (
          <Flex style={{ ...noSelect, alignItems: 'center', gap: size === 'sm' ? '6px' : 2 }}>
            {Icon}
            {children}
          </Flex>
        ) : (
          <Grid
            style={{
              position: 'relative',
              maxHeight: theme.buttons[sizedVariant].height,
              width: '100%',
              placeItems: 'center',
            }}
          >
            {showLoading && (
              <>
                <Grid
                  style={{
                    position: 'absolute',
                    inset: 0,
                    top: '2px',
                    placeItems: 'center',
                  }}
                >
                  <Box>
                    <PulseLoader {...loaderProps} />
                  </Box>
                </Grid>
                {/* MAKES SURE SIZE STAYS STABLE */}
                <Box style={{ visibility: 'hidden' }}>
                  <PulseLoader {...loaderProps} />
                </Box>
              </>
            )}
            <Grid
              style={{
                ...noSelect,
                visibility: showLoading ? 'hidden' : 'inherit',
                placeItems: 'center',
              }}
            >
              {children}
            </Grid>
          </Grid>
        )}
      </ThemeUIButton>
    )
  }
)

Button.displayName = 'Button'
