/** @jsxImportSource theme-ui */
import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'
import Balancer from 'react-wrap-balancer'
import { Box, Container, Flex, Grid, Heading, NextLink, Text } from 'ui'
import { ContactSupport } from '../components/ContactSupport'
import { ErrorPlaceholderSvg } from './ErrorPlaceholderSvg'

export interface ErrorLayoutProps {
  title: string
  message: string
  code: string | number
}

export const d = {
  codeMessage: 'errors:error-page.code-message',
  messageA: 'errors:error-page.action-msg-0',
  messageB: 'errors:error-page.action-msg-1',
  linkLocal: 'errors:error-page.action-link-0',
  linkDocs: 'errors:error-page.action-link-1',
}
export const ErrorLayout: FC<React.PropsWithChildren<ErrorLayoutProps>> = ({
  title,
  message,
  code,
}) => {
  const { t } = useTranslation('errors')

  return (
    <Container
      style={{
        minWidth: '80%',
        maxWidth: '70rem',
        minHeight: '100vh',
        display: 'grid',
        placeItems: 'center',
        py: [4, 4, 4, 4, 4, 5, 6],
      }}
    >
      <Grid
        columns={['12rem 1fr', null, null, null, '16rem 1fr', '24rem 1fr']}
        style={{ gap: [4, 4, 4, 4, 4, 5], alignItems: 'center' }}
      >
        <Box>
          <ErrorPlaceholderSvg />
        </Box>
        <Flex style={{ flexDirection: 'column', gap: 3, mb: 7 }}>
          <Heading variant="h2" color="primary" style={{ fontWeight: 'semi-bold' }}>
            <Balancer>{title}</Balancer>
          </Heading>
          <Text variant="h5">
            <Balancer>
              {message}
              <br />
              {t(d.messageA)}
              <NextLink variant="themed" style={{ '&:visited': { color: 'primary' } }} href="/">
                {t(d.linkLocal)}
              </NextLink>
              {t(d.messageB)}
              <ContactSupport text={t(d.linkDocs)} variant="link" />
            </Balancer>
          </Text>
          <Text variant="h5">{t(d.codeMessage, { code })}</Text>
        </Flex>
      </Grid>
    </Container>
  )
}
