/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Box as ThemeUIBox, BoxProps as ThemeUIBoxProps } from 'theme-ui'
import { TensorUIBaseProps } from 'typ'

export type BoxProps = Omit<ThemeUIBoxProps, 'sx' | 'style'> & TensorUIBaseProps

export const Box = forwardRef<HTMLDivElement, BoxProps>(({ style, ...props }, ref) => (
  <ThemeUIBox {...props} sx={style} ref={ref} />
))

Box.displayName = 'Box'
