/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { TextStyleVariant, useTheme } from 'theme'
import { Text as ThemeUIText, TextProps as ThemeUITextProps } from 'theme-ui'
import { TensorUIBaseProps } from 'typ'

export type TextProps = Omit<ThemeUITextProps, 'sx' | 'style'> &
  TensorUIBaseProps & {
    ellipsize?: boolean
    variant?: Omit<TextStyleVariant, 'heading'>
  }

export const Text = forwardRef<HTMLDivElement, TextProps>(
  ({ style, as = 'div', ellipsize, ...props }, ref) => {
    const theme = useTheme()
    const ellipsisStyles = ellipsize ? theme.text.ellipsize : {}
    return <ThemeUIText {...props} as={as} sx={{ ...style, ...ellipsisStyles }} ref={ref} />
  }
)

Text.displayName = 'Text'
