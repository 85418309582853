/** @jsxImportSource theme-ui */
import useTranslation from 'next-translate/useTranslation'
import { FC, useCallback } from 'react'
import store from 'store'
import { Anchor, Button, ButtonProps } from 'ui'

export const d = {
  contactSupport: 'common:contact-support',
  startsCapitalizedContactSupport: 'common:starts-capitalized-contact-support',
}

type BaseProps = {
  text?: string
  startsCapitalized?: boolean
}

type AnchorProps = BaseProps & {
  variant?: 'link'
  size?: never
}
type ButtonLinkProps = BaseProps & {
  variant: 'primary' | 'secondary' | 'tertiary' | 'danger'
  size?: ButtonProps['size']
}
export type ContactSupportProps = ButtonLinkProps | AnchorProps
export const ContactSupport: FC<ContactSupportProps> = ({
  text,
  variant = 'link',
  size = 'md',
  startsCapitalized = true,
}) => {
  const { t } = useTranslation()
  const setIsContactSupportModalOpen = store.useSetIsContactSupportModalOpen()
  const openGetSupportModal = useCallback(
    () => setIsContactSupportModalOpen(true),
    [setIsContactSupportModalOpen]
  )
  const defaultText = startsCapitalized ? t(d.startsCapitalizedContactSupport) : t(d.contactSupport)

  return (
    <>
      {variant === 'link' ? (
        <Anchor
          data-testid="contact-support"
          data-cy="contact-support-button"
          onClick={openGetSupportModal}
          style={{ color: 'primary' }}
        >
          {text ?? defaultText}
        </Anchor>
      ) : (
        <Button
          variant={variant ?? 'primary'}
          size={size}
          data-testid="contact-support"
          data-cy="contact-support-button"
          onClick={openGetSupportModal}
        >
          {text ?? defaultText}
        </Button>
      )}
    </>
  )
}
